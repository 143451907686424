<!-- @format -->

<template>
  <div class="w-full">
    <div class="border-2 border-gray-100 bg-white rounded-xl">
      <div
        class="py-4 px-5 font-medium text-gray-700 border-gray-100 border-b-2"
      >
        {{ props?.heading }}
      </div>
      <div v-if="props.loader">
        <div
          role="status"
          class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center px-4 py-4 border"
          v-for="key in 7"
          :key="key"
        >
          <div
            class="flex items-center justify-center bg-gray-300 rounded dark:bg-gray-500"
          >
            <svg
              class="w-10 h-10 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path
                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
              />
            </svg>
          </div>
          <div class="flex items-center justify-between">
            <div>
              <div
                class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-24 mb-2.5"
              ></div>

              <div
                class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-500"
              ></div>
            </div>
            <div class="pl-52 pb-3">
              <div
                class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-12 my-2"
              ></div>
              <div
                class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-12"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div
          v-if="items?.items"
          class="pl-2"
          :class="props.isDash ? 'h-80' : 'h-520 overflow-scroll'"
        >
          <div
            class="pt-4 pb-2 px-5 flex flex-col w-full gap-3 border-gray-100 border-b-2 cursor-pointer"
            v-for="(compliance, key) in items?.items"
            :key="key"
            @click="routeToPracticesResponse(compliance.id)"
          >
            <div class="flex">
              <div class="w-12 text-blue-500">
                <img
                  class="w-full"
                  src="../../../assets/icons/compliance_File.svg"
                />
              </div>
              <div class="w-full">
                <div
                  class="line-clamp-2 overflow-hidden text-base font-normal px-2 break-all capitalize line-clamp"
                >
                  {{ compliance?.name }}
                </div>
              </div>
              <div>
                <span
                  class="px-2 py-1 rounded-md bg-blue bg-opacity-10 text-blue-500"
                  >{{ compliance?.submitted_practices_count }}/{{
                    compliance?.total_practices_count
                  }}</span
                >
              </div>
            </div>
            <div class="w-full px-12">
              <div class="flex justify-between text-gray-500 text-md">
                <div class="flex gap-2 font-light items-center capitalize">
                  <span class="material-icons text-lg w-3 text-teal"
                    >format_list_bulleted_icon</span
                  >
                  {{ compliance.sub_type }}
                </div>
                <div class="flex gap-1 font-light items-center">
                  <span
                    class="material-icons text-lg w-4 text-teal"
                    v-if="icon !== 'custom'"
                  >
                    {{ icon }}
                  </span>
                  <img
                    v-else
                    src="../../../assets/icons/calendar_clock.svg"
                    class="w-4"
                  />

                  {{
                    compliance.due_date
                      ? moment(compliance.due_date).format("MMM DD, YYYY")
                      : moment(compliance.end_date).format("MMM DD, YYYY")
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex flex-col items-center justify-center text-center w-full py-10"
          :class="props.isDash ? 'h-80' : 'h-520'"
        >
          <div class="rounded-full p-12 icon-bg">
            <img src="../../../assets/icons/noData.svg" class="w-32" />
          </div>
          <div class="mt-4 text-teal font-medium flex align-middle">
            <i class="material-icons text-teal w-8">info_outlined</i>
            No {{ props?.heading }} Found
          </div>
        </div>
      </div>
      <div
        class="flex py-4 px-5 text-gray-500 font-medium border-gray-100 border-t-2 justify-between"
      >
        {{ props.isDash ? "See Complete view" : "Archives" }}
        <div
          class="flex items-center text-teal font-semibold opacity-70 cursor-pointer float-right"
          @click="routeToArchivedCompliances()"
        >
          Open
          <div class="material-icons text-lg ml-1">launch</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";

const router = useRouter();

const props = defineProps({
  heading: {
    type: String,
    required: true,
  },
  items: {
    type: Object,
    required: true,
  },
  loader: {
    type: Boolean,
    required: true,
  },
  isDash: {
    type: Boolean,
    default: false,
  },
});

const { items } = toRefs(props);

const icon = computed(() => {
  if (props.items?.status === 1) {
    return "event_available";
  } else if (props.heading === "Policies") {
    return props.compliance?.status === 4
      ? "event_available"
      : "calendar_month_icon";
  } else {
    return "custom";
  }
});

const routeToPracticesResponse = (complianceId) => {
  router.push({
    name: "hq-monitoringOfPracticeFunctionsComplianceResponses",
    params: { complianceId: complianceId },
  });
};
const routeToArchivedCompliances = () => {
  if (props.isDash) {
    router.push({
      name: "hq-monitoringOfPracticeFunctionsComplianceDashboard",
    });
  } else {
    router.push({
      name: "hq-monitoringOfPracticeFunctionsArchivedCompliances",
      params: { complianceType: props.heading },
    });
  }
};
</script>

<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
