<template>
  <div class="flex flex-col gap-8">
    <div class="grid grid-cols-1 gap-6">
      <MopfButtonsGroup :buttons="buttons" />
    </div>
    <div class="flex gap-8">
      <ComplianceList
        :heading="'Audits'"
        :loader="loader"
        :items="complianceCalenderItems['audits']"
      />
      <ComplianceList
        :heading="'Risk Assessment'"
        :loader="loader"
        :items="complianceCalenderItems['risk-assessment']"
      />
      <ComplianceList
        :heading="'Policies'"
        :loader="loader"
        :items="complianceCalenderItems['policies']"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import MopfButtonsGroup from "@/components/newUi/baseComponents/MopfButtonsGroup.vue";
import ComplianceList from "@/components/newUi/Compliance/ComplianceList.vue";
import { useStore } from "vuex";

const store = useStore();
const loader = ref(false);
const buttons = ref([
  {
    component: "hq-monitoringOfPracticeFunctionsCreateCompliance",
    text: "Create Compliance",
    icon: "edit_calendar_outlined",
    bgColor: "bg-yellow",
    textColor: "text-yellow",
  },
]);

const complianceCalenderItems = computed(() => {
  return store.getters["hqComplianceCalender/getComplianceCalenderItems"];
});

onMounted(async () => {
  loader.value = true;
  await store.dispatch("hqComplianceCalender/fetchComplianceCalenderItems").then(()=>{
    loader.value = false;
  });
});
</script>

<style scoped></style>
